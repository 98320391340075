var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VTable',{attrs:{"scrollable":""},scopedSlots:_vm._u([{key:"default",fn:function({ onTop }){return [_c('VTableRow',{attrs:{"template":_vm.gridTemplate,"head":"","sticky-head":!onTop}},[_vm._l((_vm.head),function(cell,idx){return _c('VTableCell',{key:idx},[_vm._v(_vm._s(_vm.$te(cell) ? _vm.$t(cell) : cell))])}),_c('VTableCell',{staticClass:"v-table-cell--flex-end"},[_vm._v(_vm._s(_vm.$t('actions')))])],2),_vm._l((_vm.filteredRows),function(row,rowIndex){return _c('RouterLink',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(rowIndex === _vm.filteredRows.length - 1 && _vm.getObserveVisibilityParams()),expression:"rowIndex === filteredRows.length - 1 && getObserveVisibilityParams()"}],key:row.id,attrs:{"to":row.uneditable ? '' : { name: `administration.${_vm.page}.edit`, params: { id: row.id } }}},[_c('VTableRow',{attrs:{"template":_vm.gridTemplate,"hovering":""}},[_vm._l((_vm.layoutRows[rowIndex]),function(cell,idx){return _c('VTableCell',{key:idx,attrs:{"title":cell}},[_vm._v(_vm._s(cell))])}),_c('VTableCell',{staticClass:"v-table-cell--gap-1 v-table-cell--flex-end v-replacement-action"},[_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('edit'),
                        placement: 'bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('edit'),\n                        placement: 'bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"},{name:"show",rawName:"v-show",value:(!row.uneditable),expression:"!row.uneditable"}],attrs:{"color":"green"},on:{"click":function($event){$event.preventDefault();return _vm.editRow(row)}}},[_c('EditSVG')],1),_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('stopNow'),
                        placement: 'bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('stopNow'),\n                        placement: 'bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"},{name:"show",rawName:"v-show",value:(row.stoppable),expression:"row.stoppable"}],attrs:{"color":"red"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('stop-now', row)}}},[_c('StopSVG')],1),(_vm.hasDeleteAccess)?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('delete'),
                        placement: 'bottom',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('delete'),\n                        placement: 'bottom',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"},{name:"show",rawName:"v-show",value:(!row.undelitable),expression:"!row.undelitable"}],attrs:{"color":"red"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('delete', row)}}},[_c('TrashSVG')],1):_vm._e()],1)],2)],1)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }