import { Component, Vue } from 'vue-property-decorator';
import { isBoolean, throttle } from 'lodash';
import ErrorService from '@/utils/ErrorService';

// Interfaces
import { GetRequestParams } from '@/ship/Requests/RequestParams';

@Component
export default class VAdministrationPrimaryTableMixin extends Vue {
    readonly updateItemsThrottled = throttle(this.updateItems, 500);
    readonly request!: (params: GetRequestParams) => Promise<any>;

    public items: unknown[] = [];
    public search = '';
    public searchCityId: number | null = null;
    public paginationNextPage: number | null = null;

    public getRequestParams(): GetRequestParams {
        return {};
    }

    public async updateItems(nextPage = false) {
        if (!isBoolean(nextPage)) nextPage = false;
        if (nextPage && !this.paginationNextPage) return;

        const requstParams: GetRequestParams = {
            orderBy: 'id',
            limit: 15,
            page: nextPage && this.paginationNextPage ? this.paginationNextPage : 1,
            ...this.getRequestParams(),
        };

        const response = await this.request(requstParams).catch(ErrorService.handleApiError);

        if (response) {
            this.paginationNextPage = response.data.meta.pagination!.nextPage;
            this.items = nextPage ? this.items.concat(response.data.data) : response.data.data;
        }
    }

    public created() {
        this.updateItemsThrottled();
    }
}
